@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;

    user-select: none;

    color: rgba(199, 199, 199, 0.9);
    font-family: "Montserrat";
    font-style: normal;
    line-height: 24px;
    text-align: center;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

*:focus {
    border: none;
    outline: none;
}

#root {
    position: fixed;
    width: 100%;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes opacityAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes leftAnimation {
    0% {
        transform: translateX(-150%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes rightAnimation {
    0% {
        transform: translateX(150%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes topAnimation {
    0% {
        transform: translateY(-150%);
    }
    100% {
        transform: translateY(0);
    }
}
