button {
    border: none;
    background: none;

    color: rgba(199, 199, 199, 0.9);
    transition: color 0.2s ease;
}

button:hover {
    color: rgba(147, 115, 36, 0.9);
    transition: color 0.2s ease;
}

.fill:hover svg path {
    fill: rgba(147, 115, 36, 0.9);
    transition: fill 0.5s ease;
}

.stroke:hover svg path {
    stroke: rgba(147, 115, 36, 0.9);
    transition: stroke 0.5s ease;
}
