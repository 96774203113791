.recentServer {
    transition:
        transform 0.5s,
        box-shadow 0.5s,
        background 0.5s ease-out;
}

.recentServer:hover {
    background: rgba(69, 69, 69, 0.8);
    box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.15);

    transform: translateX(-4px);
    transition:
        transform 0.5s,
        box-shadow 0.5s,
        background 0.5s ease-out;
}
