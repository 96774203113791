@media screen and (min-width: 1280px) and (max-width: 1281px) and (min-height: 720px) {
    .layout {
        row-gap: 0.5rem;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1281px) and (min-height: 960px) {
    .layout {
        row-gap: 2.25rem;
    }
}

@media screen and (min-width: 1600px) and (max-width: 1601px) and (min-height: 900px) {
    .layout {
        row-gap: 0.5rem;
    }
}

@media screen and (min-width: 1600px) and (max-width: 1601px) and (min-height: 1200px) {
    .layout {
        row-gap: 2.25rem;
    }
}

.background {
    z-index: -100;
    position: fixed;

    width: 100%;
    height: 100vh;
}

.nav-logo {
    background-image: url(../../assets/images/logo.png);
}
