.post {
    width: 47%;

    overflow-y: scroll;
    transition:
        transform 0.5s,
        box-shadow 0.5s,
        background 0.5s ease-out;
}

.post:hover {
    background: rgba(69, 69, 69, 0.6);
    box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.15);

    transform: translateX(-4px);
    transition:
        transform 0.5s,
        box-shadow 0.5s,
        background 0.5s ease-out;
}

.post::-webkit-scrollbar {
    width: 0;
    height: 0;
}

@media screen and (min-width: 1280px) and (max-width: 1281px) and (min-height: 720px) {
    .post {
        height: 384px;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1281px) and (min-height: 960px) {
    .post {
        height: 492px;
    }
}

@media screen and (min-width: 1600px) and (max-width: 1601px) and (min-height: 900px) {
    .post {
        height: 480px;
    }
}

@media screen and (min-width: 1600px) and (max-width: 1601px) and (min-height: 1200px) {
    .post {
        height: 620px;
    }
}
