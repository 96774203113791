input {
    text-shadow: none;
    transition: background-color 0.5s ease;
}

input:focus {
    background-color: #dcdcdc;
    transition: background-color 0.5s ease;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgba(199, 199, 199, 0.9) inset !important;
}
